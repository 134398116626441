import React from 'react';

function TitleBar({ title }) {
  return (
    <div className="title-bar">
      <h1>{title}</h1>
    </div>
  );
}

export default TitleBar;
