import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
function DataTable({ data }) {
  const [filterText, setFilterText] = useState('');

  const handleChange = (event) => {
    setFilterText(event.target.value);
  };

  const filteredData = data.filter(item => {
    return Object.values(item).some(value => 
      value.toString().toLowerCase().includes(filterText.toLowerCase())
    );
  });

  return (
    <Paper style={{ height: 400, overflow: 'auto' }}>
      <TextField
        value={filterText}
        onChange={handleChange}
        placeholder="Filter results"
        variant="outlined"
        style={{ margin: '10px' }}
        fullWidth
      />
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {data.length > 0 && Object.keys(data[0]).map(key => (
                <TableCell key={key}>{key}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
              <TableRow key={index}>
                {Object.values(row).map((value, cellIndex) => (
                  <TableCell key={cellIndex}>{value}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default DataTable;