import React, { useState } from 'react';
import TitleBar from './components/TitleBar';
import SearchInput from './components/SearchInput';
import DataTable from './components/DataTable';

function App() {
  const [title, setTitle] = useState('UPTSU LLM Agent');
  const [data, setData] = useState([]);

  const getQueryParam = (param)=>{
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
  }

  const token = getQueryParam('token')
  const model = getQueryParam('endpoint')
  const model_url = 'https://uptsu-llm-poc.wadhwaniai.org/api/'+model

  const fetchData = async (query) => {
    try {
      const response = await fetch(model_url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-token': token,
        },
        body: JSON.stringify({ query: query }),
      });
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div className="App">
      <TitleBar title={title} />
      <SearchInput onSubmit={fetchData} />
      <DataTable data={data} />
    </div>
  );
}

export default App;
